import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

function Square(props) {
  return (
    <button className={props.className}
            onClick={props.onClick}>
      {props.value}
    </button>
  );
}

class Board extends React.Component {
  renderSquare(i) {
    return <Square value={this.props.squares[i]}
                   key={i}
                   className={this.props.winnerLine && this.props.winnerLine.includes(i) ? "square winner" : "square"}
                   onClick={
                     () => {
                       this.props.onClick(i);
                     }
                   }/>;
  }

  renderRow(rowNo) {
    return (
      <div className="board-row" key={rowNo}>
        {
          [...Array(this.props.rows).keys()].map(colNo =>
            this.renderSquare(rowNo * this.props.rows + colNo)
          )
        }
      </div>
    );
  }

  render() {
    return (
      <div>
        {
          [...Array(this.props.rows).keys()].map(rowNo =>
            this.renderRow(rowNo)
          )
        }
      </div>
    );
  }
}

class Game extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [{
        squares: Array(9).fill(null)
      }],
      nextPlayer: 'X',
      winnerLine: null,
      stepNumber: 0,
    }
  }

  jumpTo(step) {
    this.setState({
      stepNumber: step,
      nextPlayer: (step % 2) === 0 ? 'X' : 'O',
    })
  }

  winner() {
    return this.state.winnerLine && this.state.history.length === this.state.stepNumber + 1
      ? this.state.history[this.state.stepNumber].squares[this.state.winnerLine[0]] : null;
  }

  handleClick(i) {
    if (this.winner()) {
      console.log("game over");
      return;
    }
    const history = this.state.history.slice(0, this.state.stepNumber + 1);
    const current = history[history.length - 1];
    const squares = current.squares.slice();
    console.log("handleClick ... " + i);
    if (squares[i]) {
      console.log("squares[" + i + "] has been taken");
      return;
    }
    squares[i] = this.state.nextPlayer;
    const winnerLine = calculateWinner(squares);
    if (winnerLine) {
      const winner = squares[winnerLine[0]];
      console.log("game over, winner is " + winner);
    }
    this.setState({
      history: history.concat([{
        squares: squares,
        move: i,
      }]),
      nextPlayer: this.state.nextPlayer === 'X' ? 'O' : 'X',
      stepNumber: history.length,
      winnerLine: winnerLine,
    })
  }

  render() {
    const history = this.state.history;
    const current = history[this.state.stepNumber];

    const moves = history.map((step, move) => {
      const desc = move > 0 ?
        `Go to #${move} "${((move-1) % 2) === 0 ? 'X' : 'O'}" move: ${(Math.ceil((step.move + 1) / 3))}, ${(step.move % 3 + 1)}`:
        'Go to game start';
      return (
        <li key={move}>
          <button
            className={move === this.state.stepNumber ? "current" : ""}
            onClick={() => this.jumpTo(move)}
          >{desc}</button>
        </li>
      );
    });

    let status;
    if (this.winner()) {
      status = 'Winner: ' + this.winner();
    } else if (! current.squares.includes(null)) {
      status = 'Game Over, but No Winner';
    } else {
      status = 'Next player: ' + this.state.nextPlayer;
    }

    return (
      <div className="game">
        <div className="game-board">
          <Board
            rows={3}
            squares={current.squares}
            winnerLine={this.winner() ? this.state.winnerLine : null}
            onClick={
              (i) => this.handleClick(i)
            }/>
        </div>
        <div className="game-info">
          <div>{status}</div>
          <ol>{moves}</ol>
        </div>
      </div>
    );
  }
}

function calculateWinner(squares) {
  const lines = [
    [0, 1, 2],
    [3, 4, 5],
    [6, 7, 8],
    [0, 3, 6],
    [1, 4, 7],
    [2, 5, 8],
    [0, 4, 8],
    [2, 4, 6],
  ];
  for (let i = 0; i < lines.length; i++) {
    const [a, b, c] = lines[i];
    if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
      console.log('winner is ... ' + squares[a]);
      return [a, b, c];
    }
  }
  console.log('no winner');
  return null;
}

// ========================================

ReactDOM.render(
  <Game />,
  document.getElementById('root'),
  () => { document.title = "Tic Tac Toe" }
);
